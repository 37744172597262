/* You can add global styles to this file, and also import other style files */
@import './colors';
@import './bootstrap';
@import './theme.scss';

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

button.mat-raised-button {
    float: unset !important;

    &:hover {
        background-color: $color-grey-light;
        &.mat-primary {
            background-color: $color-grey;
        }
    }
}

button.mat-button,
button.mat-flat-button,
button.mat-raised-button,
a.mat-flat-button {
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    border-radius: 160px;
    padding: 0 20px;
}

.success-snackbar {
    color: $color-white !important;
    background-color: $color-black;
}

.error-snackbar {
    color: $color-white !important;
    background-color: $color-red;
}
